<template>
  <div class="mp-container">
   {{text}}
  </div>
</template>

<script>

export default {
  name: 'linkToMp',
  data () {
    return {
      text: ' 打开微信中...'
    }
  },
  beforeCreate () {
    document.title = '打开微信中。。。'
    window.open(this.$route.query.href, '_self')
  }
}
</script>
<style lang="less" scoped>
.mp-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9999999999;
}
</style>
