import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
// import scrollSmoothTo from './utils.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) { // 如果设置标题，拦截后设置标题
    document.title = to.meta.title
  }
  next()
})

router.afterEach((to, from) => {
  if (to.name === 'about') {
    store.dispatch('getAboutType', to.params.type)
    // scrollSmoothTo(0)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
