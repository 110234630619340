<template>
  <header fluid class="hm-header position">
    <b-container>
      <b-navbar toggleable="md" class="navbar-dark">
        <b-navbar-nav>
          <b-navbar-brand>
            <router-link to="/">
              <b-img class="logo" src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010616031788/2018-11/ccd4f081-4ce3-4e82-98a0-c507b8e4b4e1.png" fluid alt="Responsive image" />
              <!-- <div class="logo-wrapper">
                <img class="logo" src="../assets/logo-mobile.png" alt="logo">
                <span>让数据将经营变得更高效</span>
              </div> -->
            </router-link>
          </b-navbar-brand>
        </b-navbar-nav>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav class="flex-between hm-link">
            <li class="nav-item">
              <router-link class="nav-link" to="/">首页</router-link>
            </li>
            <!-- <li class="nav-item">
          <router-link class="nav-link" to="/"></router-link>
        </li> -->
            <b-nav-item-dropdown text="产品介绍" right>
              <router-link class="dropdown-item" to="/hemiao">禾苗商城</router-link>
              <router-link class="dropdown-item" to="/hongyun">HONG云</router-link>
              <router-link class="dropdown-item" to="/ai">AI人脸识别</router-link>
              <router-link class="dropdown-item" to="/home">禾销</router-link>
            </b-nav-item-dropdown>
            <li class="nav-item">
              <router-link class="nav-link" to="/cooperation">合作案例</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about/hxnews">新闻中心</router-link>
            </li>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item href="tel:15392850129" class="phone flex-1">
              <!-- 联系我们：400-878-3608 -->
              联系我们：153 9285 0129
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {}
  },
  computed: mapGetters(['isHome'])
}
</script>
<style lang="less" scoped>
.position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.hm-header {
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

// .static {
//   // position: static !important;
// }

.navbar.navbar-light.navbar-expand-md {
  position: relative;
  padding: 0;
  a {
    color: #fff;
    font-size: 16px;
  }
  .dropdown-menu {
    .router-link-active {
      color: #3369fa;
    }
  }
}
.phone {
  text-align: right;
}

.logo {
  height: 38px;
}

.navbar-nav {
  flex: 1;
}

@media (max-width: 767px) {
  .phone {
    text-align: left;
  }
  .navbar.navbar-light.navbar-expand-md {
    a {
      font-size: 14px;
    }
  }
}
</style>
