<template>
  <div class="back-top flex flex-column flex-between" v-show="show">
    <a href="#" @click="backToTop" class="back"></a>
    <a href="javascript:void(0)" @click="service" class="service">
      <div class="hm-popover">
        <div class="flex flex-column flex-center">
          <a data-v-78d9b730="" href="tel:15392850129"><span data-v-78d9b730="" class="iconfont icon-weibiaoti-"></span>
            咨询电话：153-9285-0129
          </a>
          <div class="text-center">
            <!-- https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010616031788/2018-11/cb93bdc2-7da2-4978-b9e9-e071283eed12.jpg -->
            <img src="https://imgcdn.hemiao100.com/hemiaoBase/website/ewm.png" alt="">
            <div class="focus">微信关注我们</div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
import scrollSmoothTo from '../utils.js'
export default {
  data () {
    return {
      show: false
    }
  },
  mounted () {
    window.addEventListener('scroll', (e) => {
      const scrollDistance = document.documentElement.scrollTop || document.body.scrollTop
      this.show = scrollDistance > window.innerHeight
    })
  },
  methods: {
    backToTop () {
      if (typeof window.getComputedStyle(document.body).scrollBehavior === 'undefined') {
        scrollSmoothTo(0)
      }
    },
    service () {}
  }
}
</script>

<style lang="less" scoped>
.back-top {
  position: fixed;
  bottom: 20%;
  right: 0px;
  height: 88px;
  width: 42px;
  padding: 5px;
  background: #30adff;
  .back,
  .service {
    display: block;
    width: 32px;
    height: 32px;
    background: url('https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/4dfcb9dd-a5c3-4f4b-8649-386128fc06c7.png') no-repeat;
  }
  .back {
    margin-bottom: 1rem;
    background-position: -10px -52px;
  }

  .service {
    position: relative;
    background-position: -10px -10px;
  }

  .service:hover {
    .hm-popover {
      display: block;
    }
  }

  .hm-popover {
    position: absolute;
    left: -245px;
    top: -70px;
    background: rgba(0, 0, 0, 0.8);
    padding: 2rem;
    border-radius: 5px;
    color: #fff;
    transition: all 0.2s linear;
    display: none;
    img {
      width: 100px;
    }
    .focus {
      margin-top: .5rem;
    }
    a {
      color: #fff;
      margin-bottom: 2rem;
    }
  }
}
</style>
