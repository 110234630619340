<template>
  <div class="home">
    <a @click="showContact" >
      <hm-carousel msg="Welcome to Your Vue.js App" class="pc" />
    </a>
    <a href="tel:15392850129">
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_01.png" fluid alt="Responsive image" />
    </a>
    <!-- <section class="home_01">
      <b-container>
        <div class="article">
          <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_01.png" fluid alt="Responsive image" />
          <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_01_web.png" fluid alt="Responsive image" />
        </div>
      </b-container>
    </section> -->
    <section class="home_02 pc">
      <b-container>
        <h1 class="text-center title animated pc" ref="title" :class="animate1">系统自动审核发放</h1>
        <div class="pc">
          <b-row class="list text-center flex-around">
            <b-col cols="4" md="3" class="list-item-wrapper">
              <a href="#home03" class="list-item-a">
              <div class="list-item">
                <b-row class="flex-center">
                  <b-col cols="6" md="6">
                    <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_icon_05.png" alt="节省人员"></div>
                  </b-col>
                </b-row>
                <h3 class="mt1">节省人员</h3>
              </div>
              </a>
            </b-col>
            <b-col cols="4" md="3" class="list-item-wrapper">
              <a href="#home04" class="list-item-a">
              <div class="list-item">
                <b-row class="flex-center">
                  <b-col cols="6" md="6">
                    <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_icon_06.png" alt="减少工作"></div>
                  </b-col>
                </b-row>
                <h3 class="mt1">减少工作</h3>
              </div>
              </a>
            </b-col>
            <b-col cols="4" md="3" class="list-item-wrapper">
              <a href="#home05" class="list-item-a">
              <div class="list-item">
                <b-row class="flex-center">
                  <b-col cols="6" md="6">
                    <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_icon_07.png" alt="节省时间"></div>
                  </b-col>
                </b-row>
                <h3 class="mt1">节省时间</h3>
              </div>
              </a>
            </b-col>
            <b-col cols="4" md="3" class="list-item-wrapper">
              <a href="#home06" class="list-item-a">
              <div class="list-item">
                <b-row class="flex-center">
                  <b-col cols="6" md="6">
                    <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_icon_08.png" alt="减少损耗"></div>
                  </b-col>
                </b-row>
                <h3 class="mt1">减少损耗</h3>
              </div>
              </a>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </section>
    <div class="home_web_02 article mobile">
      <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_02.png" fluid alt="Responsive image" />
    </div>
    <section class="home_03 pc">
      <b-container>
        <h1 class="text-center title animated pc" ref="article1" :class="article1">节省人员<span class="line"></span></h1>
        <div class="article">
          <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_11.png" fluid alt="Responsive image" />
        </div>
      </b-container>
      <div class="location" id="home03"></div>
    </section>
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_03.png" fluid alt="Responsive image" />
    <section class="home_04 pc">
      <b-container>
        <h1 class="text-center title animated pc" ref="article2" :class="article2">减少工作<span class="line"></span></h1>
        <div class="article">
          <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_12.png" fluid alt="Responsive image" />
        </div>
      </b-container>
      <div class="location" id="home04"></div>
    </section>
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_04.png" fluid alt="Responsive image" />
    <section class="home_05 pc">
      <b-container>
        <h1 class="text-center title animated pc" ref="article3" :class="article3">节省时间<span class="line"></span></h1>
        <div class="article">
          <a class="contact" @click="showContact">
          <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_13.png" fluid alt="Responsive image" />
          </a>
        </div>
      </b-container>
      <div class="location" id="home05"></div>
    </section>
    <a href="tel:15392850129">
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_05.png" fluid alt="Responsive image" />
    </a>
    <section class="home_06 pc">
      <b-container>
        <h1 class="text-center title animated pc" ref="article4" :class="article4">减少损耗<span class="line"></span></h1>
        <div class="article">
          <a class="contact" @click="showContact">
          <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_14.png" fluid alt="Responsive image" />
          <!-- <div class="subTitle pc animated" ref="article5" :class="article5">以下连锁均有导购使用我们小程序，全国覆盖连锁系统超过10000家 </div> -->
          <!-- <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_07.png" fluid alt="Responsive image" /> -->
          </a>
        </div>
      </b-container>
      <div class="location" id="home06"></div>
    </section>
    <a href="tel:15392850129">
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_06.png" fluid alt="Responsive image" />
    </a>
    <section class="home_07 pc">
      <b-container class="pc">
          <a class="contact" @click="showContact">
            <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_15.png" fluid alt="Responsive image" />
          </a>
      </b-container>
    </section>
    <a href="tel:15392850129">
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_07.png" fluid alt="Responsive image" />
    </a>
    <div class="home_08 pc">
      <h1 class="text-center title animated pc" ref="title" :class="animate1">数据指引提升动销<span class="line"></span></h1>
      <div class="list flex flex-around">
        <b-col cols="6" md="6" class="list-item-wrapper">
          <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_16.png" alt=""></div>
        </b-col>
        <b-col cols="6" md="6" class="list-item-wrapper">
          <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_17.png" alt=""></div>
        </b-col>
      </div>
      <div class="list flex flex-around">
        <b-col cols="6" md="6" class="list-item-wrapper">
          <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_18.png" alt=""></div>
        </b-col>
        <b-col cols="6" md="6" class="list-item-wrapper">
          <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_19.png" alt=""></div>
        </b-col>
      </div>
      <a class="contact" @click="showContact">
        <div class="img-wrapper pc contact-us"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_20.png" alt=""></div>
      </a>
    </div>
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_08.png" fluid alt="Responsive image" />
    <div class="home_09 pc">
      <div class="list">
        <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_21.png" fluid alt="Responsive image" />
      </div>
    </div>
    <a href="tel:15392850129">
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_09.png" fluid alt="Responsive image" />
    </a>
    <div class="home_10 pc">
      <h1 class="h1 text-center title animated pc" ref="title">红包系统多场景应用<span class="line"></span></h1>
      <h2 class="h2 text-center title animated pc" ref="title">全行业覆盖，累计服务1000家企业，接受各行各业按需定制<span class="line"></span></h2>
      <div class="list">
        <img class="img-fluid pc" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_22.png" fluid alt="Responsive image" />
      </div>
    </div>
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_10.png" fluid alt="Responsive image" />
    <img class="img-fluid mobile" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_web_11.png" fluid alt="Responsive image" />
    <div class="home_02_fixed pc" v-if="fixedBtn">
      <b-container>
      <b-row class="list text-center flex-around">
        <b-col cols="4" md="3" class="list-item-wrapper">
          <a href="#home03" class="list-item-a">
          <div class="list-item">
            <b-row class="flex-center">
              <b-col cols="6" md="6">
                <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_icon_05.png" alt="节省人员"></div>
              </b-col>
            </b-row>
            <h3 class="mt1">节省人员</h3>
          </div>
          </a>
        </b-col>
        <b-col cols="4" md="3" class="list-item-wrapper">
          <a href="#home04" class="list-item-a">
          <div class="list-item">
            <b-row class="flex-center">
              <b-col cols="6" md="6">
                <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_icon_06.png" alt="减少工作"></div>
              </b-col>
            </b-row>
            <h3 class="mt1">减少工作</h3>
          </div>
          </a>
        </b-col>
        <b-col cols="4" md="3" class="list-item-wrapper">
          <a href="#home05" class="list-item-a">
          <div class="list-item">
            <b-row class="flex-center">
              <b-col cols="6" md="6">
                <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_icon_07.png" alt="节省时间"></div>
              </b-col>
            </b-row>
            <h3 class="mt1">节省时间</h3>
          </div>
          </a>
        </b-col>
        <b-col cols="4" md="3" class="list-item-wrapper">
          <a href="#home06" class="list-item-a">
          <div class="list-item">
            <b-row class="flex-center">
              <b-col cols="6" md="6">
                <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/hemiaoBase/website/home_icon_08.png" alt="减少损耗"></div>
              </b-col>
            </b-row>
            <h3 class="mt1">减少损耗</h3>
          </div>
          </a>
        </b-col>
      </b-row>
      </b-container>
    </div>
    <div class="hm-popover" @click="hideContact" v-if="isShowContact">
        <img src="https://imgcdn.hemiao100.com/hemiaoBase/website/contact2.png" alt="" class="image">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HmCarousel from '@/components/HmCarousel.vue'

export default {
  name: 'home',
  components: {
    HmCarousel
  },
  data () {
    return {
      animate1: '',
      article1: '',
      article2: '',
      article3: '',
      article4: '',
      article5: '',
      fixedBtn: false,
      isShowContact: false
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      const scrollDistance = document.documentElement.scrollTop || document.body.scrollTop
      this.addAnimate({ ele: 'title', model: 'animate1', animate: 'fadeInUp', scrollDistance })
      this.addAnimate({ ele: 'article1', model: 'article1', animate: 'fadeInLeft', scrollDistance })
      this.addAnimate({ ele: 'article2', model: 'article2', animate: 'fadeInRight', scrollDistance })
      this.addAnimate({ ele: 'article3', model: 'article3', animate: 'fadeInLeft', scrollDistance })
      this.addAnimate({ ele: 'article4', model: 'article4', animate: 'fadeInRight', scrollDistance })
      this.addAnimate({ ele: 'article5', model: 'article5', animate: 'fadeInUp', scrollDistance })
      if (scrollDistance > 900 && scrollDistance < 3800) {
        this.fixedBtn = true
      } else {
        this.fixedBtn = false
      }
    })
  },
  destoryed () {
    window.removeEventListener('scroll')
  },
  methods: {
    addAnimate ({ ele, model, animate, space = 200, scrollDistance }) {
      if (this.$refs[ele]) {
        this[model] = scrollDistance + space >= this.$refs[ele].offsetTop ? animate : ''
      }
    },
    hideContact () {
      this.isShowContact = false
    },
    showContact () {
      this.isShowContact = true
    }
  }
}
</script>
<style lang="less" scoped>
.platform {
  padding-top: 60px;
  .title {
    margin-bottom: 50px;
    color: #333;
    font-size: 24px;
  }
}

.quality {
  padding-top: 90px;
  .stripe {
    background: transparent;
  }
  .quality-caption {
    margin-bottom: 90px;
  }
  .quality-item {
    padding: 40px 0;
    border-bottom: 1px solid #EAEAEA;
  }
  .quality-item:last-child {
    border: none;
  }
  .quality-list {
    padding-left: 0;
    .caption {
      position: relative;
      font-size: 25px;
      color: #666;
      margin-bottom: 40px;
    }
    .caption:after {
      position: absolute;
      bottom: -20px;
      left: 0;
      content: '';
      width: 40px;
      height: 2px;
      border-radius: 3px;
      background: #666;
    }
    .intro {
      font-size: 16px;
      color: #666;
    }
  }
}

.result {
  padding-top: 40px;

  .result-list {
    .list-item-wrapper {
      margin-bottom: 40px;
    }
    .list-item {
      // cursor: pointer;
      padding: 40px 20px;
      height: 100%;
      background: #fff;
      transition: all 0.2s linear;
      box-shadow: 0px 3px 3px rgba(50, 50, 50, 0.1);
      border-radius: 5px;
      margin: 0 auto;
    }
    // .list-item:hover {
    //   box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    // }
    p {
      margin-bottom: 0.2rem;
      color: #666;
    }
    .special {
      p {
        line-height: 31px;
      }
    }
    h3 {
      font-weight: bold;
      color: #333;
      font-size: 18px;
      word-break: keep-all;
      margin-bottom: 1rem;
      font-size: 16px;
    }
    span {
      color: #666;
      font-size: 16px;
    }
  }
  .result-caption {
    margin-bottom: 40px;
  }
}

.result {
  background: rgba(241, 241, 241, 0.5);
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.pc {
  display: block;
}

.mobile {
  display: none;
}
.home_01{
  padding-top: 0;
  padding-bottom: 0;
}
.home_02,.home_02_fixed{
  padding-top: 55px;
  // padding-bottom: 124px;
  .title{
    font-size: 65px;
  }
  .list{
    margin-top: 78px;
    .list-item-wrapper{
      .list-item {
        // box-shadow: 0px 0px 40px 0px rgba(221,221,221,0.4000);
        background: none;
        border-radius: 0;
        padding: 30px;
        color: #333;
      }
      .list-item-a:hover{
        border: none;
        text-decoration: none;
        .list-item{
          border-bottom: solid 3px #2E47FF;
        }
        .mt1{
          border: none;
          text-decoration: none;
        }
      }
      h3 {
        color: #333;
        font-size: 22px;
        word-break: keep-all;
      }
    }
  }
}
.home_03,.home_04,.home_05,.home_06{
  background-image: linear-gradient(#FAF8F5, #FFFFFF);
  padding-top: 0;
  padding-bottom: 96px;
  position: relative;
  .title{
    padding: 80px 0;
    font-size: 34px;
    position: relative;
    .line{
      width: 52px;
      height: 8px;
      background: #3776FF;
      border-radius: 33px 33px 33px 33px;
      position: absolute;
      left: 50%;
      margin-left: -26px;
      top: 140px;
    }
  }
}
.home_06{
  .subTitle{
    font-size: 26px;
    padding: 100px 0 20px 0;
    text-align: center;
  }
}
.img-fluid{
  margin: 0 auto;
}
.home_07{
  background: #C2DBFF;
}
.home_08{
  padding-top: 95px;
  .title{
    font-size: 65px;
    padding-bottom: 110px;
    position: relative;
    .line{
      width: 52px;
      height: 8px;
      background: #3776FF;
      border-radius: 33px 33px 33px 33px;
      position: absolute;
      left: 50%;
      margin-left: -26px;
      top: 100px;
    }
  }
  .list{
    width: 1300px;
    margin: 0 auto;
  }
  .contact-us{
    width: 1280px;
    margin: 0 auto;
    margin-top: 100px;
    padding-bottom: 60px;
  }
}
.home_09{
  background: #15215F;
  .list{
    width: 1280px;
    margin: 0 auto;
  }
}
.home_10{
  width: 1280px;
  margin: 0 auto;
  padding-top: 70px;
  .h1{
    font-size: 34px;
  }
  .h2{
    font-size: 28px;
    margin-top: 10px;
  }
  .list{
    padding-top: 72px;
    padding-bottom: 187px;
  }
}
.contact{
  width: 100%;
  height: 100%;
  background: none;
}
@media (max-width: 767px) {
  .home{
    background: #F5F5F5;
  }
  .platform {
    padding-top: 40px;
  }
  .platform .title {
    margin-bottom: 40px;
  }
  .quality {
    .quality-caption {
      margin-bottom: 0px;
    }
    .quality-list {
      text-align: center;
      .caption {
        font-size: 20px;
      }
      .intro {
        text-align: justify;
      }
      .caption:after {
        left: 50%;
        transform: translateX(-50%)
      }
    }
  }
  .exchange {
    .article {
      order: -1;
    }
  }
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
  .result {
    .result-list {
      .list-item {
        width: 100%;
        padding: 20px 0;
        p {
          word-break: keep-all;
        }
      }
    }
  }
  .home_01,.home_02,.home_03,.home_04,.home_05,.home_06{
    padding-top: 20px;
    padding-bottom: 0;
  }
}
.home_02_fixed{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #FFFFFF;
  padding: 0;
  z-index: 99;
  .list{
    margin: 0;
    .list-item-wrapper{
      .list-item{
        padding: 0 30px;
      }
    }
  }
}
.location{
  position: absolute;
  left: 0;
  top: -130px;
  width: 100%;
  height: 1px;
}
.hm-popover {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  border-radius: 5px;
  color: #fff;
  transition: all 0.2s linear;
  display: block;
  z-index: 100;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 543px;
    height: 487px;
    margin-left: -271px;
    margin-top: -243px;
  }
}
</style>
