<template>
  <b-carousel id="carousel1" style="text-shadow: 1px 1px 2px #333;" indicators background="#ababab" :interval="3000" img-width="1024" img-height="480" v-model="slide" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">

    <!-- Text slides with image -->
    <b-carousel-slide img-src="https://imgcdn.hemiao100.com/hemiaoBase/website/banner22.png">
      <!-- <a href="tel:15392850129" class="contact"></a> -->
      <!-- <p class="text animated slideInDown">互联网·大数据·人工智能</p>
      <h1 class="caption animated slideInDown">智慧零售精准化</h1> -->
    </b-carousel-slide>

    <!-- Text slides with image -->
    <!-- <b-carousel-slide img-src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/59394c1b-407d-4a18-a113-07714bde6292.jpg">
      <p class="text animated slideInDown">互联网·大数据·人工智能</p>
      <h1 class="caption animated slideInDown">智慧零售精准化</h1>
    </b-carousel-slide> -->

    <!-- Slides with custom text -->
    <!-- <b-carousel-slide img-src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/30c9e2cb-89fb-47e1-8058-8c369a45929b.jpg">
      <p class="text animated slideInDown">全渠道·多场景·高效率</p>
      <h1 class="caption animated slideInDown">线上线下一体化</h1>
    </b-carousel-slide> -->

    <!-- Slides with image only -->
    <!-- <b-carousel-slide img-src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/e52f7f63-cf43-4879-b15c-ecd17aa6e809.jpg">
      <p class="text animated slideInDown">商品·用户·消费</p>
      <h1 class="caption animated slideInDown">零售要素数字化</h1>
    </b-carousel-slide> -->

    <!-- Slides with img slot -->
    <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
    <!-- <b-carousel-slide img-src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/fc215659-4935-44cd-a9f7-08d203e3246d.jpg">
      <p class="text animated slideInDown">销售·服务·管理</p>
      <h1 class="caption animated slideInDown">运营场景智能化</h1>
    </b-carousel-slide> -->

    <!-- Slide with blank fluid image to maintain slide aspect ratio -->
    <!-- <b-carousel-slide img-src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/451cdc95-d125-430d-afdf-0954e4816458.jpg">
      <p class="text animated slideInDown">数据分析·内容监测·咨询顾问</p>
      <h1 class="caption animated slideInDown">全方位提供一站式服务</h1>
    </b-carousel-slide> -->

  </b-carousel>
  <!-- <b-row class="mt-2">
    <b-col cols="6" sm="6" md="3">
      <b-card title="Card Title" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top tag="article" style="max-width: 20rem;" class="mb-2">
        <p class="card-text">
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </p>
        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </b-col>
    <b-col cols="6" sm="6" md="3">
      <b-card title="Card Title" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top tag="article" style="max-width: 20rem;" class="mb-2">
        <p class="card-text">
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </p>
        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </b-col>
    <b-col cols="6" sm="6" md="3">
      <b-card title="Card Title" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top tag="article" style="max-width: 20rem;" class="mb-2">
        <p class="card-text">
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </p>
        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </b-col>
    <b-col cols="6" sm="6" md="3">
      <b-card title="Card Title" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top tag="article" style="max-width: 20rem;" class="mb-2">
        <p class="card-text">
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </p>
        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </b-col>
  </b-row> -->
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
#carousel1{
  margin-top: 0;
}
.carousel-caption {
  top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  .text {
    font-size: 25px;
    letter-spacing: 4px;
  }
  .caption {
    font-size: 60px;
    letter-spacing: 10px;
  }
}
.contact{
  width: 100%;
  height: 100%;
  background: none;
}
.pc {
  display: block;
}

.mobile {
  display: none;
}
@media (max-width: 767px) {
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
#carousel1{
  margin-top: 52px;
}
  .carousel-caption {
    .text {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 0;
    }
    .caption {
      font-size: 24px;
      letter-spacing: 4px;
    }
  }
}

.carousel-item {
  max-height: 640px;
  overflow: hidden;
}
</style>
