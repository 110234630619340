import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    type: 'intro',
    isNewsDetials: false,
    index: ''
  },
  mutations: {
    getAboutType (state, data) {
      state.type = data
    },
    queryNewsDetails (state, data) {
      state.isNewsDetials = data.isEssence
      state.index = data.index
    }
  },
  actions: {
    getAboutType ({ commit }, data) {
      commit('getAboutType', data)
    },
    queryNewsDetails ({ commit }, data) {
      commit('queryNewsDetails', data)
    }
  },
  getters: {
    type: state => state.type,
    isNewsDetials: state => state.isNewsDetials,
    index: state => state.index
  }
})
