import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import LinkToMp from './views/LinkToMp.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/linkToMp',
      name: 'linkToMp',
      meta: { title: '跳转微信中。。。' },
      component: LinkToMp
    },
    {
      path: '/about/:type',
      name: 'about',
      meta: { title: '关于我们' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/hemiao',
      name: 'hemiao',
      meta: { title: '禾苗商城' },
      component: () => import('./views/Hemiao.vue')
    },
    {
      path: '/home',
      name: 'home',
      meta: { title: '禾苗商城' },
      component: () => import('./views/Home.vue')
    },
    {
      path: '/hongyun',
      name: 'hongyun',
      meta: { title: 'hong云' },
      component: () => import('./views/Hongyun.vue')
    },
    {
      path: '/ai',
      name: 'ai',
      meta: { title: 'AI人脸识别' },
      component: () => import('./views/Ai.vue')
    },
    {
      path: '/cooperation',
      name: 'cooperation',
      meta: { title: '合作案例' },
      component: () => import('./views/Cooperation.vue')
    }
  ]
})
