<template>
  <div id="app">
    <hm-header v-if="isNotLinkToMp" />
    <router-view />
    <hm-footer  v-if="isNotLinkToMp" />
    <hm-back-top v-if="isNotLinkToMp"  />
  </div>
</template>
<script>
import HmHeader from '@/components/HmHeader'
import HmFooter from '@/components/HmFooter'
import HmBackTop from '@/components/HmBackTop'
export default {
  components: { HmHeader, HmFooter, HmBackTop },
  data () {
    return {
      isNotLinkToMp: true
    }
  },
  watch: {
  },
  mounted () {
    this.isNotLinkToMp = this.$route.name !== 'linkToMp'
  }
}
</script>

<style lang="less">
@import "./style/common.less";
@import "./style/animate.css";
</style>
