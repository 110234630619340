// 传统的JS平滑滚动处理代码...
/**
 @description 页面垂直平滑滚动到指定滚动高度
@author zhangxinxu(.com)
*/
export default function scrollSmoothTo (position) {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = (callback, element) => setTimeout(callback, 17)
  }
  // 当前滚动高度
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  // 滚动step方法
  const step = function () {
    // 距离目标滚动距离
    const distance = position - scrollTop
    // 目标滚动位置
    scrollTop = scrollTop + distance / 5
    if (Math.abs(distance) < 1) {
      window.scrollTo(0, position)
    } else {
      window.scrollTo(0, scrollTop)
      requestAnimationFrame(step)
    }
  }
  step()
}
